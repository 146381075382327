import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import { ClientsCard } from './Card'
import { IClients } from './types'

import './Clients.styles.scss'

const clients: IClients[] = [
  {
    photoId: 'autor1',
    name: 'Paul',
    position: `Consultant Engineer,\nBasalt factory project`,
    path: '/making-bim-for-construction-site/',
    text: 'Thanks to SIGNAX, all construction participants (contractors, designers, consultants) have unified and transparent information on any mobile device online. It allows us\u00A0to\u00A0follow the project progress and be sure of the final result.',
  },
  {
    photoId: 'autor2',
    name: 'Tim',
    position: 'Digital Manager,\nMered',
    text: 'Our engineers work with SIGNAX directly on the construction site, which allows them to reduce the time for checking and approving the scope of work performed by contractors.',
  },
  {
    photoId: 'autor3',
    name: 'Helen',
    position: 'Project Director,\nSeverin Development',
    text: 'During this implementation project, SIGNAX team demonstrated its competence in understanding BIM technology (in design and on site) and\u00A0the\u00A0ability to adapt international software solutions to the specifics of\u00A0regional business.',
  },
]

const ClientsSection: React.FC = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <section className="clients-section" id="clients-section">
      <div className="clients-container">
        <h2 className="clients-title">Clients</h2>
        <Swiper
          freeMode={true}
          wrapperClass="clients-wrap"
          pagination={{
            clickable: true,
            bulletClass: 'clients-pagination',
            bulletActiveClass: 'active',
          }}
          slidesPerView="auto"
          breakpoints={{
            1440: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination]}
        >
          <div className="flex justify-between w-100 h-100">
            {clients.map((item, idx) => (
              <SwiperSlide className="clients-slide" key={idx}>
                <ClientsCard
                  key={item.name}
                  image={
                    item.photoId &&
                    images[item.photoId].childImageSharp.gatsbyImageData
                  }
                  name={item.name}
                  position={item.position}
                  path={item.path}
                  text={item.text}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </section>
  )
}

export default ClientsSection

const imagesQuery = graphql`
  query {
    autor1: file(relativePath: { eq: "author/Paul.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    autor2: file(relativePath: { eq: "author/Timofey_Lyutomsky.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
    autor3: file(relativePath: { eq: "author/Helen.png" }) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: NONE)
      }
    }
  }
`
